/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from "../containers/company/banner";
import About from "../containers/company/about";

const CompanyPage = () => (
  <Layout>
    <SEO title="Company" />
    <Banner />
    <About />
  </Layout>
);
export default CompanyPage;
